import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../layout/layout"
import SEO from "../layout/seo"
import Header from "../layout/header"
import MiniGallery from "../components/gallery/miniGallery"
import { Padding } from "styled-components-spacing"
import { Line } from "../components/styled/layout"
import { Title } from "../components/styled/typography"

const GalleryPage = ({ data }) => {
  const slides = data.graphcms.header.slides?.map(el => ({
    title: el.title,
    subtitle: el.subtitle,
    image: el.image?.imageFile?.childImageSharp.fluid,
    imageAlt: el.image?.alt ?? el.title,
    backgroundOpacity: el.darken / 100 ?? 0.45,
  }))

  const galleries = [
    {
      title: data.graphcms?.galleryA?.name,
      description: data.graphcms?.galleryA?.description?.html,
      images: data.graphcms.galleryA?.images?.map(el => ({
        id: el.id,
        original: el.original?.childImageSharp.resize.src,
        thumbnail: el.thumbnail?.childImageSharp.fluid,
        width: el.original?.childImageSharp.resize.width,
        height: el.original?.childImageSharp.resize.height,
        alt: el.alt,
      })),
    },
    {
      title: data.graphcms?.galleryB?.name,
      description: data.graphcms?.galleryB?.description?.html,
      images: data.graphcms.galleryB?.images?.map(el => ({
        id: el.id,
        original: el.original?.childImageSharp.resize.src,
        thumbnail: el.thumbnail?.childImageSharp.fluid,
        width: el.original?.childImageSharp.resize.width,
        height: el.original?.childImageSharp.resize.height,
        alt: el.alt,
      })),
    },
    {
      title: data.graphcms?.galleryC?.name,
      description: data.graphcms?.galleryC?.description?.html,
      images: data.graphcms.galleryC?.images?.map(el => ({
        id: el.id,
        original: el.original?.childImageSharp.resize.src,
        thumbnail: el.thumbnail?.childImageSharp.fluid,
        width: el.original?.childImageSharp.resize.width,
        height: el.original?.childImageSharp.resize.height,
        alt: el.alt,
      })),
    },
    {
      title: data.graphcms?.galleryFALA?.name,
      description: data.graphcms?.galleryFALA?.description?.html,
      images: data.graphcms.galleryFALA?.images?.map(el => ({
        id: el.id,
        original: el.original?.childImageSharp.resize.src,
        thumbnail: el.thumbnail?.childImageSharp.fluid,
        width: el.original?.childImageSharp.resize.width,
        height: el.original?.childImageSharp.resize.height,
        alt: el.alt,
      })),
    },
    {
      title: data.graphcms?.gallery2D?.name,
      description: data.graphcms?.gallery2D?.description?.html,
      images: data.graphcms.gallery2D?.images?.map(el => ({
        id: el.id,
        original: el.original?.childImageSharp.resize.src,
        thumbnail: el.thumbnail?.childImageSharp.fluid,
        width: el.original?.childImageSharp.resize.width,
        height: el.original?.childImageSharp.resize.height,
        alt: el.alt,
      })),
    },
  ]

  return (
    <Layout>
      <SEO
        title="Ogrodzenia panelowe Kępno | Produkcja i montaż"
        description="Oferujemy sprawdzony system ogrodzeń panelowych w dowolnych wersjach, rozmiarach, ocynkowanych lub malowanych proszkowo w dowolnym kolorze. Kępno - Mroczeń"
      />
      <Header darkBackgroundOpacity={0.05} slides={slides} />
      <Padding as="header" top="6">
        <Title uppercase align="center">
          Ogrodzenia panelowe
        </Title>
        <Line center width="calc(8% + 40px)"></Line>
      </Padding>
      {galleries.map((el, index) => (
        <MiniGallery
          key={index}
          images={el.images}
          title={el.title}
          description={el.description}
        />
      ))}
    </Layout>
  )
}

export const query = graphql`
  query {
    test: file(relativePath: { eq: "test.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    graphcms {
      header(where: { id: "cklgmktqgkml90a05wopbm0ar" }) {
        slides {
          title
          subtitle
          darken
          image {
            url
            alt
            imageFile {
              childImageSharp {
                fluid(
                  maxWidth: 2560
                  srcSetBreakpoints: [1920, 1440, 1200, 1024]
                  quality: 90
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }

      galleryA: gallery(where: { id: "cklh35usgos0i0a05u8tpsb6b" }) {
        name
        description {
          html
        }
        images {
          id
          url
          width
          height
          alt
          thumbnail: imageFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          original: imageFile {
            childImageSharp {
              resize(width: 2560) {
                src
                width
                height
              }
            }
          }
        }
      }

      galleryB: gallery(where: { id: "ckm66d4y077zn0a63hkud6m48" }) {
        name
        description {
          html
        }
        images {
          id
          url
          width
          height
          alt
          thumbnail: imageFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          original: imageFile {
            childImageSharp {
              resize(width: 2560) {
                src
                width
                height
              }
            }
          }
        }
      }

      galleryC: gallery(where: { id: "ckm68ysuwghlr0b10gaau3gu7" }) {
        name
        description {
          html
        }
        images {
          id
          url
          width
          height
          alt
          thumbnail: imageFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          original: imageFile {
            childImageSharp {
              resize(width: 2560) {
                src
                width
                height
              }
            }
          }
        }
      }

      gallery2D: gallery(where: { id: "ckmi6plxk9g070b03maelh7gj" }) {
        name
        description {
          html
        }
        images {
          id
          url
          width
          height
          alt
          thumbnail: imageFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          original: imageFile {
            childImageSharp {
              resize(width: 2560) {
                src
                width
                height
              }
            }
          }
        }
      }

      galleryFALA: gallery(where: { id: "ckm66eq1kfm4q0b10yjhtydgj" }) {
        name
        description {
          html
        }
        images {
          id
          url
          width
          height
          alt
          thumbnail: imageFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          original: imageFile {
            childImageSharp {
              resize(width: 2560) {
                src
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

export default GalleryPage
