import React, { useState } from "react"
import styled from "styled-components"
import hexToRgba from "../../utils/hexToRgba"

import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

import { Gallery, Item } from "react-photoswipe-gallery"
import { Container, Row, Col, media } from "styled-bootstrap-grid"
import { Section, Center } from "../styled/layout"
import { Content, Subtitle } from "../styled/typography"
import { Margin } from "styled-components-spacing"
import LazyImage from "../styled/lazyImage"
import Button from "../styled/button"

const GallerySection = styled(Section)`
  padding: 0;

  ${media.md`  
    padding: 30px 0;
  `}
`

const GalleryImage = styled(LazyImage)`
  height: 110px !important;

  ${media.sm`  
    height: 145px !important;
  `}

  ${media.md`  
    height: 70px !important;
  `}

  ${media.lg`  
    height: 105px !important;
  `}

  ${media.xl`  
    height: 100px !important;
  `}

  box-shadow: 0px 2px 10px ${({ theme }) => hexToRgba(theme.colors.dark, 0.1)};
  transition: box-shadow ease-in-out 200ms;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 6px 25px
      ${({ theme }) => hexToRgba(theme.colors.dark, 0.25)};
  }
`

const GalleryRow = styled(Row)`
  flex-direction: column-reverse;
  ${media.md`  
    flex-direction: row;
  `}
`

const GalleryCol = styled(Col)`
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  ${media.sm`  
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
  `}
  ${media.md`  
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  `}
`

const MyGallery = ({ title, description, images, ...props }) => {
  const initialImages = 12
  const [visibleImages, setVisibleImages] = useState(initialImages)

  const imagesDOM = [...images].slice(0, visibleImages).map(el => (
    <Item
      original={el.original}
      width={el.width}
      height={el.height}
      title={el.alt || null}
      key={el.id}
    >
      {({ ref, open }) => (
        <GalleryCol md="4" col="6" ref={ref} onClick={open}>
          <GalleryImage src={el.thumbnail} cover />
        </GalleryCol>
      )}
    </Item>
  ))

  return (
    <GallerySection {...props}>
      <Container>
        <GalleryRow>
          {images && images.length > 0 ? (
            <>
              <Col md="6" xl="5">
                <Row>
                  <Gallery
                    options={{
                      shareEl: false,
                      clickToCloseNonZoomable: false,
                      getDoubleTapZoom: function (isMouseClick, item) {
                        if (item.initialZoomLevel < 0.4) {
                          return 0.9
                        }
                        if (item.initialZoomLevel < 0.7) {
                          return 1.2
                        }
                        if (item.initialZoomLevel < 1) {
                          return 1.8
                        }
                        return 2
                      },
                      maxSpreadZoom: 2,
                    }}
                  >
                    {imagesDOM}
                  </Gallery>
                </Row>
                {images.length > visibleImages && (
                  <Center>
                    <Button
                      primary
                      onClick={() =>
                        setVisibleImages(visibleImages + initialImages)
                      }
                    >
                      Więcej
                    </Button>
                  </Center>
                )}
              </Col>
              <Col md="6" xl="7">
                <Margin bottom="3" top={{ sm: 2, md: 0 }}>
                  <Subtitle marginless={true}>{title}</Subtitle>
                </Margin>
                <Content
                  dangerouslySetInnerHTML={{ __html: description }}
                ></Content>
              </Col>
            </>
          ) : (
            <Col xs="!12">
              <Margin bottom="3" top={{ sm: 2, md: 0 }}>
                <Subtitle marginless={true}>{title}</Subtitle>
              </Margin>
              <Content
                dangerouslySetInnerHTML={{ __html: description }}
              ></Content>
            </Col>
          )}
        </GalleryRow>
      </Container>
    </GallerySection>
  )
}

MyGallery.defaultProps = {
  images: [],
  title: "",
}

export default MyGallery
